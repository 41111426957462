import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import TogetherBanner from "../../img/design/together/together-banner.png"
import MobileTogetherBanner from "../../img/design/together/mobile-banner.png"
import InitialFindings from "../../img/design/together/initial-findings.png"
import UserFlow from "../../img/design/together/user-flow.png"
import LofiWireframe from "../../img/design/together/lofi-wireframe.png"
import FeatureIteration from "../../img/design/together/feature-iteration.png"
import DesignSystem from "../../img/design/together/design-system.png"
import HifiWireframe from "../../img/design/together/hifi-wireframe.png"
import NextSteps from "../../img/design/together/next-steps.png"

import "../style/DetailsPage.css"

const Together = () => {
    return (
        <div>
            <Navigation active="design"/>
            <MobileNavigation active="design"></MobileNavigation>
            <div className="content">
                <img src={TogetherBanner} className="banner"/>
                <img src={MobileTogetherBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Together: Long distance cooking </h2>
                        <p>September 2021 (3 weeks) | Design Challenge | Mobile Product and UX Design</p>
                    </div>    

                    <div className="text-content-p">
                        <p>Together is a cooking app for friends and family who are living separately. They should be able to share and follow along with recipes while cooking together across their different households.</p>
                        <p>Below, I have included a brief on my design process and final mockups. To see the full case, visit the link below.</p>
                    </div>
                    <ButtonLink text="View full case study →" link="https://www.figma.com/file/Ry8FJJoRmbEwyJ2WHwMNBv/Together---Vicky-Li?node-id=819%3A4"></ButtonLink>

                    <div className="my-process">
                        <h3>My Design Process</h3>
                        <div className="bullet-list">
                            <p>1. <span className="bold-body">Research</span> - Conduct user interviews to identify key pains and features.</p>
                            <p>2. <span className="bold-body">Plan</span> - Build user flow and plan feature design through a lo-fi wireframe.</p>
                            <p>3. <span className="bold-body">Iterate</span> - Test out lo-fi wireframe with users and identify improvements.</p>
                            <p>4. <span className="bold-body">Design</span> - Create a design system and implement a hi-fi wireframe.</p>
                        </div>
                    </div>

                    <div className="goals-features">
                        <h3>Key Goals and Features</h3>
                        <p>When first ideating features for the application, I had two main goals in mind from the prompt. </p>
                        <p>First, users should have an easy way to discover and share recipes among their groups. </p>
                        <p>Secondly, users should be able to cook live with their groups so that groups can truly feel connected when cooking together from afar.</p>
                    </div>

                    <div className="initial-findings">
                        <h3>Initial Findings from Interviews</h3>
                        <p>I spent some time chatting with my friends who like to cook to gain some insights on how they like to cook with recipes and the biggest pains of doing so. I also asked them how they like to socialize with friends when they are not together.</p>
                        <img src={InitialFindings}></img>
                    </div>

                    <div className="user-flow">
                        <h3>Defining User Flow</h3>
                        <p>To organize my features and thoughts, I created a basic user flow map to depict the way that users would move across the application.</p>
                        <img src={UserFlow}></img>
                    </div>

                    <div className="lofi-wireframe">
                        <h3>Lo-Fi Wireframe</h3>
                        <p>To better visualize the user flow and features, I created a low-fidelity wireframe. Below are some of the screens I created.</p>
                        <img src={LofiWireframe}></img>
                    </div>

                    <div className="feature-iteration">
                        <h3>Feature Design Iteration</h3>
                        <p>After designing the layout and core features through the lo-fi wireframe, I interviewed a few more people to see what sorts of features they would like to see on top of what already existed in the current wireframe. Here are the key insights:</p>
                        <img src={FeatureIteration}></img>
                    </div>

                    <div className="design-system">
                        <h3>Design System</h3>
                        <p>After interviewing, I was able to move forward with creating a very basic style guideline consisting of colors and typography to guide the creation of the high-fidelity wireframe.</p>
                        <img src={DesignSystem}></img>
                    </div>

                    <div className="hifi-wireframe">
                        <h3>High Fidelity Wireframe</h3>
                        <p>After identifying missing features and getting feedback through user interviewers, I was able to move forward with a high fidelity wireframe that incorporated these fixes as well as the design system that I developed for the application. Below are a subset of frames from my high-fidelity mockup.</p>
                        <img src={HifiWireframe}></img>
                    </div>

                    <div className="next-steps">
                        <h3>Next Steps</h3>
                        <p>While I learned a lot from building this application, I would like to continue iterating the application in the following ways:</p>
                        <img src={NextSteps}></img>
                    </div>

                </div>
                <Footer />
            </div>
        </div>
    )
}
export default Together
