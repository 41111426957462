import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/design/circles/banner.png"
import MobileBanner from "../../img/design/circles/mobile-banner.png"
import Screens from "../../img/design/circles/screens.png"

import "../style/DetailsPage.css"

const Circles = () => {
    return (
        <div>
            <Navigation active="design"/>
            <MobileNavigation active="design"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>A group-oriented social platform</h2>
                        <p>May 2020 - June 2020 | Side Project | Mobile Product Design</p>
                    </div>    

                    <div className="text-content-p">
                        <p>In the Summer of 2020, a group of friends and I worked on a social media concept that put groups at the forefront of its design. We wanted to have a place to connect with our different groups of friends and share content among ourselves. Our solution was Circles: a group-first social media platform.</p>
                        <p>To view the full prototype, visit the link below.</p>
                    </div>

                    <ButtonLink text="View full project →" link="https://www.figma.com/file/7nsaTVKl3MGqokh7J66Avw/Circles---Vicky-Li?node-id=1620%3A2"/>

                    <div>
                        <div>
                            <h3>Our Inspiration </h3>
                            <p>Our reason for building this application came from a boring quarantine summer. After going home due to the pandemic, we started discussing the dynamics of the “group chat” and how they can help us stay connected. However, we recognized that the group chats we were all in often die quickly. We identified a couple of the main pains of group conversations. </p>
                        </div>
                        <div>
                            <h4>🎞️ Poor content sharing</h4>
                            <p>One of the main drivers of group connections is the ability to share “inside jokes” and sentiments around certain subjects. The current solutions for content sharing are clunky and not well-integrated. For example, the way that images clutter up the messaging portion of a group message.</p>
                        </div> 
                        <div>
                            <h4>📲 Scattered interactions</h4>
                            <p>Many of our interactions were scattered across various apps like Facebook Messenger, iMessage, Snapchat, and more. This made maintaining interactions much more difficult as we would have to tend to different apps and adjust to the rules of interaction of different platforms.</p>
                        </div>                    
                    </div>

                    <div>
                        <div>
                            <h3>Our Goal </h3>
                            <p>Our vision for this application was a place where users can share content specific to their groups. Each group would have a dedicated feed that only members can see. Our inspiration for this was partially due to the rise of private accounts and the use of “close friends” features like on Instagram stories. </p>
                            <p>We also wanted to allow users to specify the level at which their content is shared.</p>
                        </div>
                    </div>

                    <div style={{marginBottom: 50 + 'px'}}>
                        <div>
                            <h3>Our Solution</h3>
                            <p>The application centered around three layers of communication: global view, group communication, and individual communiciation. </p>
                            <p>On the left is global view, where users can see what is happening across all of their groups and friends. In the middle is where you can view your groups and dive into group conversations and content feeds. On the right is the individual level where you can have conversations with friends one-on-one. These levels are navigatable through a slider at the bottom of the screen.</p>
                            <p>Furthermore, each group or individual chat page has a dedicated section for content browsing. Each piece of content is able to be referenced in the messaging section in a space-efficient way.</p>
                       </div>
                       <img src={Screens} alt="screens" draggable={false} />
                    </div>


                </div>
                
                <Footer />
            </div>
        </div>
    )
}
export default Circles
