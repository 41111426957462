import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import WDBBanner from "../../img/design/wdb/banner.png"
import MobileWDBBanner from "../../img/design/wdb/mobile-banner.png"
import BrandElements from "../../img/design/wdb/brand-elements.png"
import StoryTelling from "../../img/design/wdb/story-telling.png"
import IGPosts from "../../img/design/wdb/ig-posts.png"


import "../style/DetailsPage.css"

const WDB = () => {
    return (
        <div>
            <Navigation active="design"/>
            <MobileNavigation active="design"></MobileNavigation>
            <div className="content">
                <img src={WDBBanner} className="banner"/>
                <img src={MobileWDBBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Building an organization brand image</h2>
                        <p>May 2020 - December 2021 | Passion Project | Brand and Marketing Design</p>
                    </div>    

                    <div className="text-content-p">
                        <p>In my first year at UC Berkeley, I realized there was a significant gap in industry-applicable web development education at the school. After learning computer science fundamentals, I attended a hackathon where I got to experience the magic of building my own application for the first time. Through this trial-by-fire experience, I set out to provide these same building opportunities for my peers at Cal.</p>
                        <p>Thus, Web Development at Berkeley (WDB) was born. Visit the link below to learn more about the organization.</p>
                    </div>

                    <ButtonLink text="View website →" link="https://www.webatberkeley.org/"/>

                    <div>
                        <div>
                            <h3>Telling a Story </h3>
                            <p>One of my priorities when founding and marketing my organization was to make our energy and excitement to build stand out in a sea of other organizations, especially during a fully virtual semester. To do so, every piece of our branding and identity had to tell a story. </p>
                        </div>
                        <img src={BrandElements} alt="brand elements" draggable={false} /> 
                        <p>One of the first pieces I focused on was color. I wanted to choose a color scheme that is both highly memorable and extendable for future iterations of our brand design. The use of cool, electric colors conveyed high levels of energy. The use of gradients between similar colors made the colors easily extendable for various use cases but not so extreme that the brand loses its cohesion.</p>
                    </div>

                    <div>
                        <div>
                            <h3>Selling WDB</h3>
                            <p>Another main strategy that we used to capture attention was to tell the story of our origins and make our marketing people-oriented. Since the idea of this organization came to us after a hackathon weekend surrounded by some amazing people, we wanted to emphasize the joy and magic of building together through our marketing. We focused on putting other students in the same shoes as we were in when first coming to Berkeley through our narrative.</p>
                        </div>
                        <img src={StoryTelling} alt="storytelling" draggable={false} /> 
                        <p>These branding elements allowed us to tell our story and let the reader in on who we are and our values. By focusing pieces of our marketing on the people behind the operation, we created a more personable and trustworthy identity.</p>
                    </div>

                    <div>
                        <div>
                            <h3>Brand Development</h3>
                            <p>Below are several iterations of our branding across multiple semesters. (I worked with a team of designers for many of these.)</p>
                        </div>
                        <img src={IGPosts} alt="ig posts" draggable={false} /> 
                        <p>Varying our branding every semester helped refresh our look and generate excitement for the upcoming semesters.</p>
                        <ButtonLink text="View Instagram →" link="https://www.instagram.com/webatberkeley/?hl=en" />
                    </div>
                    
                    <div>
                        <div>
                            <h3>Conclusion</h3>
                            <p>In my two years leading WDB, it’s flourished into an amazing family of developers and designers. Since its conception, we’ve worked with over 35 clients, from companies to non-profits to other Berkeley clubs. We’ve also been able to spread web development education by starting our own Berkeley course on Full Stack Development, teaching 300+ students since our founding.</p>
                            <p>Getting to shape the brand identity of the organization has been one of the coolest experiences in college so far.</p>
                            <p>Visit the Figma file linked below to view the rest of my brand work for WDB.</p>
                       </div>
                       <ButtonLink text="View more designs →" link="https://www.figma.com/file/fD5BvjzDHjzMEqVOFGnJkF/WDB---Vicky-Li?node-id=1612%3A833" />
                    </div>

                    

                </div>
                
                <Footer />
            </div>
        </div>
    )
}
export default WDB
