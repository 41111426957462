import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/code/bill/banner.png"
import MobileBanner from "../../img/code/bill/mobile-banner.png"
import Schedule from "../../img/code/bill/sched-pay-empty.png"
import Vendor from "../../img/code/bill/vendor-pay-empty.png"
import Bulk from "../../img/code/bill/bulk-pay-empty.png"

import "../style/DetailsPage.css"

const Bill = () => {
    return (
        <div>
            <Navigation active="code"/>
            <MobileNavigation active="code"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Software Engineering @ Bill.com</h2>
                        <p>May 2021 - August 2021 | Frontend Engineering | Angular and GraphQL | Work Experience </p>
                    </div>    

                    <div className="text-content-p">
                        <p>At Bill.com, I was given a chance to work on the <span className="bold-body">Simba</span> team. Simba, standing for simple + basic, is Bill.com’s most simple AR (accounts receivable) and AP (accounts payable) solution targeted towards sole proprietors. By the end of my internship, Simba had moved over <span className="bold-body">$2M</span>. I specifically worked on features of Bill.com within the Bank of America website application.</p>
                        <p>At Bill.com, I worked on two main projects which I cover below.</p>
                        <ButtonLink text="View company website →" link="https://www.bill.com/"/>
                    </div>

                    <div>
                        <div>
                            <h3>Project #1 - Empty States </h3>
                            <p>My first task at Bill.com was to build the empty states within the application. Empty states are moments in a user's experience with a product where there is nothing to display. Empty states <span className="bold-body">improve</span> user experience and engagement by <span className="bold-body">hinting</span> users towards the correct action to take on the page. </p>
                            <p>Through this project I was able to communicate with designers, get warmed up with <span className="bold-body">Angular and GraphQL</span> for the first time, and get the hang of moving around the codebase.</p>
                        </div>
                        <img src={Schedule} style={{width: 70 + "%", marginTop: 20 + 'px', marginBottom: 20 + 'px'}}/>
                        <img src={Vendor} style={{width: 70 + "%", marginTop: 20 + 'px', marginBottom: 20 + 'px'}}/>
                    </div>

                    <div>
                        <div>
                            <h3>Project #2 - Bulk Payment </h3>
                            <p>My second project involved building out the bulk payment feature within Simba. Bulk pay allow users to schedule up to 20 bills at one time, <span className="bold-body">shortening and simplifying</span> the user workflow. </p>
                            <p>Since I was working on this independently, I got a chance to learn about how different roles work together to discuss feature requirements and feasibility.</p>
                        </div>
                        <img src={Bulk} style={{width: 70 + "%", marginTop: 20 + 'px', marginBottom: 20 + 'px'}}/>
                        <p>For this feature, I worked on </p>
                        <div className="bullet-list">
                            <p>- Building the overall interface layout with attention to detail regarding spacing, type, and look and feel</p>
                            <p>- Fetching and parsing through data using GraphQL</p>
                            <p>- Standardizing the styling across the page</p>
                            <p>- Passing input data from user to the schedule payment step</p>
                        </div>
                    </div>

                    <div>
                        <h3>Takeaways </h3>
                        <p>Through my internship at Bill.com, I was able to learn both soft and hard skills. </p>
                        <p>Being that it was my first time using the tech stack, I had to pick up Angular and GraphQL at the very beginning of my internship while starting my projects and getting acclimated to the codebase and ecosystem.</p>
                        <p>Furthermore, I learned what it was like to write good code through code reviews and the many pipelines that my code had to pass through. I also learned how to test frontend code and learned the importance of <span className="bold-body">test-driven development</span>.</p>
                        <p>Lastly, I got the chance to work with an amazingly supportive team and practice my <span className="bold-body">presentational skills</span> through passion presentations and more.</p>
                    </div>
                </div>
                
                <Footer />
            </div>
        </div>
    )
}
export default Bill
