import React from "react"
import { Link } from "@reach/router"

import "./style/Navigation.css"
const Navigation = (props) => {

    return (
        <div className="nav">
            <Link to="/" className={(props.active === "about" ? "nav-elem active" : "nav-elem")}>
                About Me
            </Link>
            <Link to="/design" className={(props.active === "design" ? "nav-elem active" : "nav-elem")}>
                Design
            </Link>
            <Link to="/code" className={(props.active === "code" ? "nav-elem active" : "nav-elem")}>
                Code
            </Link>
            <Link to="/for-fun" className={(props.active === "for-fun" ? "nav-elem active" : "nav-elem")}>
                For Fun
            </Link>
        </div>
    )
}
export default Navigation
