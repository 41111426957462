import React from "react"

import Navigation from "../components/Navigation"
import MobileNavigation from "../components/MobileNavigation"

import Footer from "../components/Footer.js"
import Card from "../components/Card"

import Line from "../img/grad-line.png"

import Bill from "../img/code/bill/card.png"
import Canoo from "../img/code/canoo/card.png"
import Huddle from "../img/code/huddle/card.png"
import Decal from "../img/code/decal/card.png"
import CS70 from "../img/code/cs70/card.png"
import Data8 from "../img/code/data8/card.png"

import "./style/Code.css"

const Code = () => {
    return (
        <div>
             <Navigation className="nav" active="code" />
             <MobileNavigation className="mobile-nav" active="code" />
             <div className="body-content">
                <div className="heading">   
                    <h1>Code</h1>
                    <div className="header-p">
                        <p>Ever since I was young, I’d find myself obsessing over the latest technologies. I’d often just watch videos about Tesla autopilot in class and just tear up because of how cool the tech was. Going into college, I decided to pursue a Computer Science degree to begin exploring technology.</p>
                        <p>Nowadays, after coming to UC Berkeley to study CS, most of my work has been in building web products, but I am newly excited about exploring how we can solve problems using ML, computer science education, and how tech can be used to do good things.</p>
                    </div>
                </div>
                <img src={Line} className="line"/>
                <div className="work-section">
                    <h4 style={{marginBottom: 40 + "px"}}>Some work I've done</h4>
                    <div className="some-work">
                        <Card link="/code/bill" image={Bill} title="Software Engineering @ Bill.com" description="Frontend Engineering  | Work Experience"></Card>
                        <Card link="/code/canoo" image={Canoo} title="Software Operations @ Canoo" description="Frontend Engineering, UI Design | Work Experience"></Card>
                        <Card link="/code/huddle" image={Huddle} title="Redefining virtual social interactions" description="Product and Brand Design  | Passion Project"></Card>
                    </div>
                </div>
                <div className="work-section">
                    <h2>Teaching</h2>
                    <div className="some-work">
                        <Card link="/code/decal" image={Decal} title="Introduction to Full Stack Development" description="Founder and TA | Teaching"></Card>
                        <Card link="/code/cs70" image={CS70} title="Discrete Math and Probability Theory " description="Academic Intern | Teaching"></Card>
                        <Card link="/code/data8" image={Data8} title="Foundations of Data Science" description="Tutor | Teaching"></Card>
                    </div>
                </div>
             </div>
             <Footer/>
        </div>
    )
}
export default Code