import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import ButtonLink from "../../components/ButtonLink"
import MobileNavigation from "../../components/MobileNavigation"

import RedfinBanner from "../../img/design/redfin/redfin-banner.png"
import RedfinBannerMobile from "../../img/design/redfin/mobile-banner.png"
import MetaAudit from "../../img/design/redfin/meta-audit.png"
import MetaFirstIter from "../../img/design/redfin/meta-first-iter.png"
import MetaFinalIter from "../../img/design/redfin/meta-final-iter.png"
import DTAudit from "../../img/design/redfin/dynamic-type-audit.png"
import DTSelectiveAudit from "../../img/design/redfin/dynamic-type-selective-audit.png"
import DTSelectiveRules from "../../img/design/redfin/dynamic-type-selective-rules.png"
import ColumnLayout from "../../img/design/redfin/column-layout.png"
import ExpandableContainers from "../../img/design/redfin/expandable-containers.png"
import Multiline from "../../img/design/redfin/multiline.png"
import DocsitePage from "../../img/design/redfin/docsite-page.png"
import Stay from "../../img/design/redfin/stay-mocks.png"

import "../style/DetailsPage.css"

const Redfin = () => {
    return (
        <div>
            <Navigation active="design"/>
            <MobileNavigation active="design"></MobileNavigation>
            <div className="content">
                <img src={RedfinBanner} className="banner"/>
                <img src={RedfinBannerMobile} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Product Design @ Redfin</h2>
                        <p>May 2022 - August 2022 | Work Experience | Product Design Intern, Design Systems</p>
                    </div>    

                    <div className="text-content-p">
                        <p>I was able to spend an amazing 13 weeks at Redfin in Summer of 2022, honing my technical design skills and exploring the wonderful world of industry product design for the first time. During my time here, I was able to exercise my design <span className="bold-body">research</span> skills, learn how to effectively <span className="bold-body">articulate</span> my design decisions, and learn how to address <span className="bold-body">accessibility</span> and <span className="bold-body">scalability</span> within a design system. </p>
                        <p>Given the nature of working on design systems, my projects presented a unique challenge in that designers themselves are the users of my product. (So I spent most of the summer designing products for designers! Pretty cool!)</p>
                        <p>Below, I included briefs on my internship projects. Feel free to reach out to learn more about my work at Redfin!</p>
                    </div>

                    <div className="meta-system-project">
                        <div className="meta-intro">
                            <h3>Project #1 - Creating a meta design system</h3>
                            <p>The Redfin Blueprint design system as a product, has a separate brand identity from Redfin itself. Previously, Blueprint had a scattered and unorganized design system, leading to in-cohesive presentation and lack of brand cohesion.</p>
                            <p>My goal was to create a design system for the Blueprint design system that is consistent with the team’s vision and the system’s values and mission.</p>
                        </div>
                        <ButtonLink text="View full project →" link="https://www.figma.com/file/F6pGiDw8OWnqbCnJrhJA5H/Meta-System---Vicky-Li?node-id=0%3A1"></ButtonLink>
                        <div >
                            <h4>Audit of the current materials</h4>
                            <p>To gather necessary components of the meta system, I did an audit of the Blueprint documentation website and presentational materials.</p>
                            <img src={MetaAudit}/>
                            <p>Through auditing the existing materials, I started to get an understanding of what types, colors, and components to include in the design system.</p>
                        </div>
                        <div style={{marginBottom: 24 + "px"}}>
                            <h4>The first iteration</h4>
                            <p>After auditing and ensuring all color styles were accessible in both light and dark mode, I put together the first version of my meta system, including color, type, and icon guidelines.</p>
                            <img src={MetaFirstIter}/>
                            <p>After reviewing this version of the system with my teammates, I got a few key pieces of feedback that I later built on:</p>
                            <div className="bullet-list">
                                <p>- Ensure that the typefaces included are the bare minimum required to support their various use cases. Does every typeface serve a necessary use case or can we remove redundancies and edge cases?</p>
                                <p>- Provide usage guidelines for each style to help designers know how to use your design system.</p>
                                <p>- How strict is your system? Define clear guidance on how and when to extend this system as it’s being used.</p>
                            </div>
                        </div>
                        <div className="meta-final-iter" style={{marginBottom: 40 + "px"}}>
                            <h4>The final iteration</h4>
                            <p>Through applying the feedback above and going through a couple more iterations, I reached my final iteration.</p>
                            <img src={MetaFinalIter}/>
                            <p>Some key changes included improvements on organization, guidelines for extending the system, usage guidelines, and a better balance of scalability and specificity.</p>
                        </div>
                    </div>

                    <div className="dynamic-type-project">
                        <div className="dynamic-type-intro" style={{marginBottom: 45 + "px"}}>
                            <h3>Project #2 - Dynamic Type</h3>
                            <p>Dynamic Type is an iOS feature that allows users to change the size of the text on their screens based on their needs and preferences. With approximately 25% of users selecting larger text sizes, improper Dynamic Type support within the Redfin iOS application is a major accessibility issue.</p>
                            <p>My goal was to research Dynamic Type and create a set of guidelines that would help designers build products with dynamic content sizes in mind.</p>
                            <ButtonLink text="View full project →" link="https://www.figma.com/file/dk1diZEh3o5Pmmk3mnWbrm/Dynamic-Type---Vicky-Li?node-id=0%3A1"/>
                        </div>
                        <div>
                            <h4>Mobile application audit</h4>
                            <p>The first step I took was to increase the text size on my phone and audit the Redfin iOS application to look for common problems and content patterns.</p>
                            <img src={DTAudit}/>
                            <p>Through the audit, I came across a set of common issues within the application.</p>
                            <div className="bullet-list">
                                <p>- Overflowing and cut-off text</p>
                                <p>- Cluttered layouts and disrupted content hierarchy</p>
                                <p>- Poor text truncation, loss of meaning</p>
                            </div>
                        </div>
                        <div className="dynamic-type-scope" style={{marginBottom: 24 + "px"}}>
                            <h4>Defining scope</h4>
                            <p>After auditing the app, I narrowed down the scope of my guidelines to two main pieces:</p>
                            <div className="bullet-list">
                                <p>1. Selective sizing: How should designers choose which elements should or shouldn’t scale based on priority?</p>
                                <p>2. Designing for Dynamic Type: What guidelines should designers follow to create layouts that withstand changing content sizes and maintain accessibility standards?</p>
                            </div>
                        </div>
                        <div className="dynamic-type-selective" style={{marginBottom: 24 + "px"}}>
                            <h4>Selective Sizing</h4>
                            <p>Within mobile applications, screen space is extremely valuable. Thus, designers must be able to decide which content to scale and prioritize when users select larger text sizes. To create a guideline for selective sizing, I performed another audit of the application to see what sorts of content existed within it.</p>
                            <img src={DTSelectiveAudit}/>
                            <p>Through this audit, I determined what types of information were to be prioritized over others.</p>
                            <img src={DTSelectiveRules}/>
                            <p>As a general rule, components which are overlayed on other content, such as navigation and search bars, do not require scaling as their consistent placement across applications can communicate their usage without the need for text labels. Furthermore, nested components should not be scaled in general.</p>
                        </div>
                        <div className="dynamic-type-designing" style={{marginBottom: 24 + "px"}}>
                            <h4>Designing for Dynamic Type</h4>
                            <p>Following these set of guidelines, designers can create layouts that are flexible to dynamic content sizes. Below, I have included a subset of the guidelines I proposed.</p>
                            <div className="guidelines-dt" style={{maxWidth: 95 + "%"}}>
                                <div className="column-layout">
                                    <h5>Column Layouts</h5>
                                    <p>Making use of vertical space</p>
                                    <img src={ColumnLayout}/>
                                </div>
                                <div className="expandable-containers">
                                    <h5>Expandable Containers</h5>
                                    <p>Containers flex as content sizes change</p>
                                    <img src={ExpandableContainers}/>
                                </div>
                                <div className="multiline">
                                    <h5>Multiline Text</h5>
                                    <p>Avoiding text truncation</p>
                                    <img src={Multiline}/>
                                </div>
                            </div>
                        </div>
                        <div className="dynamic-type-designing" style={{marginBottom: 40 + "px"}} >
                            <h4>The final product</h4>
                            <p>Finally, after creating these guidelines, I compiled them into a readable and organized page within the Blueprint documentation site for designers to reference.</p>
                            <img src={DocsitePage}/>
                        </div>
                        <div className="other-work">
                            <h3>Other work</h3>
                            <p>Besides the two main projects I worked on, I got the chance to explore various other aspects of working at Redfin! </p>

                            <div>
                                <h4>Emerging Talent Marketing</h4>
                                <p>I worked closely with the Emerging Talent team to promote the 2023 internship program. Through ideating and creating content for TikTok and Instagram, I was able to create videos that reached over <span className="bold-body">150k potential applicants</span>. </p>
                                <p>Furthermore, through this effort, we <span className="bold-body">quadrupled</span> the total number of applicants from the previous year within just one month of application release.  </p>
                            </div>
                            <ButtonLink text="View example video →" link="https://www.tiktok.com/@redfin_emergingtalent/video/7131021989246094638?is_copy_url=1&is_from_webapp=v1&item_id=7131021989246094638"></ButtonLink>

                            <div >
                                <h4>Hackathon Project: Redfin Stay</h4>
                                <p>At our company-wide hackathon, I was able to explore the concept of a short-term rental application within Redfin. (Note: this project is purely a hypothetical design exploration) I worked with a team of software engineers and designers to create a prototype for this concept. </p>
                                <img src={Stay} style={{marginBottom: 16 + "px", marginTop: 16 + "px"}}/>
                            </div>
                            <ButtonLink text="View full project →" link="https://www.vickyli.me/for-fun/redfin-stay"></ButtonLink>

                            <div >
                                <h4>Other explorations </h4>
                                <div classname="bullet-list">
                                    <p>- Assisted in the <span className="bold-body">rollout</span> and <span className="bold-body">beta testing</span> process for the Redfin design system.</p>
                                    <p>- Worked on defining the design system and standards for Redfin <span className="bold-body">dark mode</span>.</p>
                                    <p>- <span className="bold-body">Translated</span> the iOS Listing Details Page to the new design system.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default Redfin
