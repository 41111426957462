import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/code/data8/banner.png"
import MobileBanner from "../../img/code/data8/mobile-banner.png"

import "../style/DetailsPage.css"

const Data8 = () => {
    return (
        <div>
            <Navigation active="code"/>
            <MobileNavigation active="code"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Foundations of Data Science</h2>
                        <p>January 2020 - May 2022 | Tutor | DATA 8</p>
                    </div>    

                    <div className="text-content-p">
                        <p>In Spring of 2020, I was able to be a FemTech Launch tutor, teaching a group of 5 female-identifying students in Berkeley’s Foundations of Data Science (DATA 8) course. </p>
                        <p>Each week, my co-tutor and I would lead hour-long sections to cover fundamental data science topics covered in the course. When the pandemic began, we had to devise ways to shift and adjust to online learning. </p>                        
                    </div>

                    <div>
                        <p>Some of the concepts that I helped teach included <span className="bold-body">A/B testing, confidence intervals, bootstrapping, pandas, NumPy, regression, classification, tables</span>, and more.</p>
                    </div>

                    <ButtonLink text="View course website →" link="https://www.eecs70.org/"/>                    
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default Data8
