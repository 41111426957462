import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import HuddleBanner from "../../img/design/huddle/huddle-banner.png"
import MobileHuddleBanner from "../../img/design/huddle/mobile-banner.png"
import LofiMockup from "../../img/design/huddle/lofi-mockup.png"
import LogoIterations from "../../img/design/huddle/first-logo-iterations.png"
import FinalLogo from "../../img/design/huddle/final-logo.png"
import BrandingInitial from "../../img/design/huddle/branding-initial.png"
import FinalBranding from "../../img/design/huddle/branding-final.png"
import InterfaceGif from "../../img/design/huddle/final-interface.gif"

import "../style/DetailsPage.css"

const Huddle = () => {
    return (
        <div>
            <Navigation active="design"/>
            <MobileNavigation active="design"></MobileNavigation>
            <div className="content">
                <img src={HuddleBanner} className="banner"/>
                <img src={MobileHuddleBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Redefining virtual social interactions</h2>
                        <p>May 2020 -  December 2020 | Passion Project | Brand and Product Design</p>
                    </div>    

                    <div className="text-content-p">
                        <p>When my first year at UC Berkeley went remote due to COVID, I, along with many of my peers, couldn’t help but feel as though a critical part of our college experience was missing. Having to do everything over video call, our social relationships and abilities to form bonds and experience little moments with our friends was gone. </p>
                        <p>Thus, three of my friends and I decided to build Huddle: a group video call application that allows natural and dynamic social gatherings online. </p>
                        <p>As the designer on this project, I was able to experience the full lifecycle of product development and look at design through a different lens. This project was a huge design challenge, as real time user-to-user interactions is a difficult thing to predict and accommodate for.</p>
                        <p>Below, I have included a brief on my design process.</p>
                    </div>

                    <ButtonLink text="View full project →" link="https://www.figma.com/file/cAF9aAFA9ysf3jjAHKdzyL/Huddle---Vicky-Li?node-id=1722%3A12935"/>

                    <p>I also worked as a frontend engineer on this project. To view the technical brief, visit the link below.</p>

                    <ButtonLink text="View technical brief →" link="https://www.vickyli.me/code/huddle"/>

                    <div className="user-pains">
                        <div>
                            <h3>User Pains</h3>
                            <p>When we first started building this product, we spoke to our peers at Berkeley to identify user pain points. Here were the main three that we focused on.</p>
                        </div>
                        <div>
                            <h4>👥 Single threaded conversations</h4>
                            <p>Current solutions simply aren’t suited for social conversations. Group interactions are clumsy, awkward, and detached. Part of this is due to the two dimensional nature of a video call, but other problems include the fact that only one participant can speak at a time, lack of body language cues, and more.</p>
                        </div>
                        <div>
                            <h4>🍿 Shared experiences</h4>
                            <p>When in-person, many social gatherings revolved around activities and some form of share experience. With current video solutions, these activities become makeshift solutions such as screensharing audio and video. These temporary solutions are buggy, laggy, and often difficult to set up.</p>
                        </div>
                        <div>
                            <h4>🏡 Persistence</h4>
                            <p>People experience friction when attempting to start a call and join a room with friends. When someone wants to start a call, they must send a link, and people found themselves sharing the same link over and over again, or having to search for the link somewhere.</p>
                        </div>
                    </div>

                    <div className="first-solution">
                        <div>
                            <h3>Our First Solution</h3>
                            <p>After many nights of calls and brainstorming, we landed on a couple key features and value propositions that would define the first iteration of our application.</p>
                        </div>
                        <div>
                            <h4>🏃🏻‍♀️ Break out of rooms</h4>
                            <p>Experience a life-like conversation through intuitive spacial video arrangements and the ability to branch off into side conversations.</p>
                        </div>
                        <div>
                            <h4>🤖 Enhance interactions</h4>
                            <p>Supercharge social gatherings by integrating “bots” that provide share experiences like a Netflix show or game. These bots help your room share a unified mood or experience more naturally.</p>
                        </div>
                        <div>
                            <h4>❤️️ Create belonging</h4>
                            <p>Allow users to create persistent groups that can be easily accessed and customized to reduce the friction of starting a call.</p>
                        </div>
                    </div>

                    <div className="lofi-mockup">
                        <div>
                            <h3>Lo-Fi Mockup</h3>
                            <p>After defining these features, I created a low-fidelity mockup of my vision for the application.</p>
                        </div>
                        <img src={LofiMockup}></img>
                    </div>

                    <div className="user-interviews">
                        <div>
                            <h3>User Interview Findings</h3>
                            <p>After creating the lo-fi mockup, we conducted a few user interviews to gain insights about the application features and layout without focusing on visuals. These were the two main conclusions we drew.</p>
                        </div>
                        <div>
                            <h4>😵‍💫 Too much clutter</h4>
                            <p>Surrounding huddle previews should include less information, too many video circles happening on one screen is overwhelming.</p>
                            <p>To remedy this, we were able to modify the previews to hold less information but expand the size of the information to make it more accessible.</p>
                        </div>
                        <div>
                            <h4>🤨 Confusion with shared experiences</h4>
                            <p>The concept of the experience “bots” were confusing, and people were not used to these experiences taking the form of another user.</p>
                            <p>Instead of bots, we created the concept of the experience drawer where users can pull in experiences from their set of selections in a side panel.</p>
                        </div>
                        <p>Using these insights, we implemented changes and began to build out our MVP in code.</p>
                    </div>

                    <div className="brand-design">
                        <div>
                            <h3>Brand Design</h3>
                            <p>While building out our MVP, I did some work regarding the product branding and identity. In order to convey our product correctly, we needed to align its branding to our product vision and goals.</p>
                            <p>From the very beginning, our focus was to build an application that was fluid and dynamic, allowing users to flow freely between conversations and experiences. Thus, I knew that our branding had to convey that as well.</p>
                        </div>

                        <div>
                            <h3>Logo work</h3>
                            <p>Pictured below are some of the first iterations of our logo. We played around with the concept of wave patterns, gradients, and symbols that indicated motion. Thus, many of our designs included lines and organic shapes to compliment those notions. None of these logos fully captured our vision, some were too unreadable, too simple, or just not memorable enough.</p>
                            <img src={LogoIterations}/>
                            <p>After some more tries, we ultimately landed on this logo which incorporated not only gradients and layering, but also included a circle; a common motif within our application interface design.</p>
                            <img src={FinalLogo} style={{width: 400 + "px"}}/>
                        </div>

                        <div>
                            <h3>Branding work</h3>
                            <p>The next step was to identify how we would shape our brand identity through our visual language. Going with the same thought process as the logo, we wanted our branding to be bold, dynamic, and fluid.</p>
                            <div>
                                <p>Our first iteration involved a very simple and clean interface made up of a single color scheme. We emphasized openness and lightness in our design.</p>
                                <img src={BrandingInitial}/>
                            </div>
                            <div>
                                <p>We ultimately decided that this theme was too light and didn’t communicate our brand vision well. Thus, we created a second version of the design that was much more bold and colorful, capturing the fluidity and moldability of our platform and how we empower users to do more with video call.</p>
                                <img src={FinalBranding}/>
                            </div>
                        </div>
                        
                        <div>
                            <h3>Hi-Fi Mockup</h3>
                            <p>For the MVP of the application, I created a high-fidelity design incorporating feedback and branding elements.</p>
                            <img src={InterfaceGif} style={{width: 90 + "%"}}/>
                        </div>

                        <div>
                            <h3>Usability Testing</h3>
                            <p>Using the developed MVP, we ran some usability testing sessions with groups of varying sizes, from 1-10 participants at a time. Through this testing, we were able to catch various bugs in our code while gaining insights on how we can improve the user experience. There were two main design-related insights that we received:</p>
                            <div>
                                <h4>🏛 Iconography and information architecture</h4>
                                <p>The iconography was unclear, making it confusing to move quickly through the application. Furthermore, messy button/controls placements also contributed to this issue. In real-time communication, speed and understanding of the interface is crucial.</p>
                                <p>To remedy this, we grouped our controls into related sections and modified our iconography to be more standard.</p>
                            </div>
                            <div>
                                <h4>👀 Information transparency</h4>
                                <p>Users had a hard time becoming comfortable with what other participants could see or hear within in a huddle. For example, some users were hesitant to send messages due to transparency settings being unclear within the app.</p>
                                <p>To fix this issue, we included tooltips and flyout messages that communicated what information could or couldn’t be seen and by who to ease the experience of the user.</p>
                            </div>
                        </div>

                        <div>
                            <h3>Challenges</h3>
                            <p>This project was a particularly challenging one, as it dealt with a space that I had never previously designed for. Here are the four main challenges that we faced.</p>
                            <div>
                                <h4>🗣 Working with RTC</h4>
                                <p>The interface must be highly intuitive to allow users to react quickly in a real-time setting. Without an intuitive interface, the user will experience strain and anxiety when using our application.</p>
                            </div>
                            <div>
                                <h4>👓 Content Accessbility</h4>
                                <p>With so much information on the screen, creating spacial relations and allocating space was difficult. I had to devise ways to prioritize certain content and create intuitive previews to convey just enough information.</p>
                            </div>
                            <div>
                                <h4>👪 Defining User-to-User Interactions</h4>
                                <p>Who can see what? How can we make users feel comfortable talking and sharing in their huddles? Privacy is a large concern within social conversations, and we did not want our application design to hinder discussion.</p>
                            </div>
                            <div>
                                <h4>💻 Design to Technical Implementation</h4>
                                <p>Many features and functionalities posed a difficult technical challenge. Furthermore, state management proved to be difficult to handle with so much data coming in and out of each user’s experience.</p>
                            </div>
                        </div>

                        <div>
                            <h3>Takeaways</h3>
                            <p>Overall, this project was an extremely interesting problem to explore, and it was super cool to get to both design and engineer this complex application. If I were to redo it, I would focus more on user interviews at the very beginning to ensure we aren’t spending valuable time engineering useless features.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default Huddle
