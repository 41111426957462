import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/design/huddle/huddle-banner.png"
import MobileBanner from "../../img/design/huddle/mobile-banner.png"
import InterfaceGif from "../../img/design/huddle/final-interface.gif"
import Landing from "../../img/code/huddle/landing.png"


import "../style/DetailsPage.css"

const HuddleCode = () => {
    return (
        <div>
            <Navigation active="code"/>
            <MobileNavigation active="code"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Redefining virtual social interactions</h2>
                        <p>May 2020 - December 2020 | Design and Frontend Engineering | React + Typescript, Twilio  | Passion Project</p>
                    </div>    

                    <div className="text-content-p">
                        <p>When my first year at UC Berkeley went remote due to COVID, I, along with many of my peers, couldn’t help but feel as though a critical part of our college experience was missing. Having to do everything over video call, our social relationships and abilities to form bonds and experience little moments with our friends was gone.</p>
                        <p>Thus, three of my friends and I decided to build Huddle: a group video call application that allows natural and dynamic social gatherings online.</p>
                        <p>As both a designer and frontend engineer on this project, I was able to work on a broad array of tasks and dive into a very technically challenging experience.</p>
                    </div>

                    <div>
                        <p><span className="bold-body">Some tasks that I worked on included:</span></p>
                        <div className="bullet-list">
                            <p>- Building the link sharing feature that allowed users to copy a link with the meeting ID of their current room to share to other users who would like to join.</p>
                            <p>- Built and styled the live chat feature within calls.</p>
                            <p>- Designed and built website for the project using React and CSS.</p>
                        </div>
                        <p>To learn more about how I designed Huddle, visit the link below.</p>
                    </div>

                    <ButtonLink text="View design brief →" link="https://www.vickyli.me/design/huddle"/>

                    <div>
                        <h4>Application Demo</h4>
                        <img src={InterfaceGif} style={{width: 90 + "%", marginTop: 20 + "px"}}/>
                    </div>

                    <div>
                        <h4>Company Website</h4>
                        <img src={Landing} style={{width: 90 + "%", marginTop: 20 + "px"}}/>
                    </div>

                </div>
                <Footer />
            </div>
        </div>
    )
}
export default HuddleCode
