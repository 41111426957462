import React from "react"

import Navigation from "../components/Navigation"
import MobileNavigation from "../components/MobileNavigation"

import Footer from "../components/Footer.js"
import Card from "../components/Card"

import Line from "../img/grad-line.png"
import Redfin from "../img/design/redfin/redfin.png"
import Together from "../img/design/together/together.png"
import Huddle from "../img/design/huddle/huddle.png"
import WDB from "../img/design/wdb/wdb.png"
import Circles from "../img/design/circles.png"


import "./style/Design.css"

const Design = () => {
    return (
        <div>
             <Navigation className="nav" active="design" />
             <MobileNavigation className="mobile-nav" active="design" />
             <div className="body-content">
                <div className="heading">   
                    <h1>Design</h1>
                    <div className="header-p">
                        <p>Coming from an engineering background, my <span className="bold-body">technical</span> skills and <span className="bold-body">design-thinking</span> capabilities empower me to build end-to-end products and solutions with the benefit of all parties in mind.</p>
                        <p>Currently, I’m excited about <span className="bold-body">experience design</span>, exploring how usability and design principles <span className="bold-body">evolve</span> with technology, and learning about how design can be leveraged to <span className="bold-body">alleviate</span> complex social issues.</p>
                    </div>
                </div>
                <img src={Line} className="line"/>
                <div className="work-section">
                    <h4 style={{marginBottom: 40 + "px"}}> Some work I’m excited about</h4>
                    <div className="some-work">
                        <Card link="/design/redfin" image={Redfin} title="Product Design @ Redfin" description="Design Systems | Work Experience"></Card>
                        <Card link="/design/together" image={Together} title="Together: Cooking from a distance " description="Mobile Product Design | Design Challenge"></Card>
                        <Card link="/design/huddle" image={Huddle} title="Redefining virtual social interactions" description="Product and Brand Design  | Passion Project"></Card>
                        <Card link="/design/wdb" image={WDB} title="Building an organization brand image" description="Brand Design  | Passion Project"></Card>
                        <Card link="/design/circles" image={Circles} title="A group-oriented social platform" description="Mobile Product Design  | Passion Project"></Card>
                    </div>
                </div>
             </div>
             <Footer/>
        </div>
    )
}
export default Design