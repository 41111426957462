import React from "react"
import { Link } from "@reach/router"

import Navigation from "../components/Navigation"
import MobileNavigation from "../components/MobileNavigation"
import Footer from "../components/Footer"
import Card from "../components/Card"

import Me from "../img/new-me.png"
import HeroBG from "../img/hero-bg.jpg"

import Redfin from "../img/design/redfin/redfin.png"
import Together from "../img/design/together/together.png"
import Huddle from "../img/design/huddle/huddle.png"
import HappyDrops from "../img/forfun/happy-drops/card.png"
import Bill from "../img/code/bill.png"
import Canoo from "../img/code/canoo.png"



import "./style/Landing.css"

const Landing = () => {
    return (
        <div>
            <Navigation className="nav" active="about" />
            <MobileNavigation className="mobile-nav" active="about" />
            <div className="hero">
                <img src={HeroBG} alt="vicky" className="hero-bg" draggable={false} />
                <p className="hero-text">I’m Vicky, a driven <span className="pd-text">product designer </span> and <span className="swe-text">software_engineer</span><span className="swe-text-mobile">software engineer</span>.</p>
                
            </div>
            <div className="body-content-landing">
                <div className="currently">
                    <h2> Currently... </h2>
                    <p className="curr-facts">
                        <p className="fact">📽 <span className="fact-bold">Working on  <a style={{fontWeight: 700, color: "inherit"}} href="https://tiktok.com/@design.talks" target="_blank" rel="noreferrer">@design.talks</a> and <a style={{fontWeight: 700, color: "inherit"}} href="https://tiktok.com/@vickyyli" target="_blank" rel="noreferrer">@vickyyli</a>:</span> spending countless hours editing videos, illustrating on Procreate, finding community, and basically just talking to myself</p>
                        <p className="fact">📍 <span className="fact-bold">Exploring my hometown in Minnesota:</span> learning to love home, befriending my neighbor's cat, playing loads of Mario Kart, and being a tourist in my own town</p>
                        <p className="fact">🛠 <span className="fact-bold">Rediscovering the joys of building:</span> ideating fun app ideas, embarking on a constant learning journey through design + engineering</p>
                    </p>
                </div>

                <div className="biography">
                    <img src={Me} alt="vicky" className="me-photo" draggable={false} />  
                    <p className="bio-text">
                        <span style={{fontWeight: 700}}>It’s nice to meet you!</span> <br/><br/>
                        I’m Victoria, and I was born in Canada, Minnesota-raised, and now living in the Bay Area. <br/><br/>
                        When not coding or designing, I find joy in <a style={{fontWeight: 700, color: "inherit"}} href="https://webatberkeley.org/" target="_blank" rel="noreferrer">building communities</a>, many forms of dance, and <a style={{fontWeight: 700, color: "inherit"}} href="https://tiktok.com/@vickyyli" target="_blank" rel="noreferrer">creating content</a> for the world to see and learn from! <br/><br/>
                        Check out some of my work below, and don’t hesitate to <a style={{fontWeight: 700, color: "inherit"}} href="https://www.linkedin.com/in/victoriayli/" target="_blank" rel="noreferrer">reach out</a> with opportunities, ideas, or just to chat. I am currently open to any freelance or full-time product design or software engineering opportunities!
                    </p>
                </div>

                <div className="work-preview">
                    <h2>Some of my work</h2>
                    <div className="some-work">                
                        <Card link="/design/redfin" image={Redfin} title="Product Design @ Redfin" description="Design Systems | Work Experience"></Card>
                        <Card link="/design/together" image={Together} title="Together: Cooking from a distance " description="Mobile Product Design | Design Challenge"></Card>
                        <Card link="/design/huddle" image={Huddle} title="Redefining virtual social interactions" description="Product and Brand Design  | Passion Project"></Card>
                        <Card link="/for-fun/happy-drops" image={HappyDrops} title="Creating cute things that make people smile " description="Brand Design, Business Dev | Startup"></Card>
                        <Card link="/code/bill" image={Bill} title="Software Engineering @ Bill.com" description="Frontend Engineering  | Work Experience"></Card>
                        <Card link="/code/canoo" image={Canoo} title="Software Operations @ Canoo" description="Frontend Engineering, UI Design | Work Experience"></Card>
                    </div>
                </div>

                <div className="contact">
                    <h2>Connect with me</h2>
                    <p>Email me at <a href="mailto:victoria.li@berkeley.edu">victoria.li@berkeley.edu</a> or reach out to me on <a href="https://www.linkedin.com/in/victoriayli/">LinkedIn!</a></p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Landing
