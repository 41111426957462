import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/code/canoo/banner.png"
import MobileBanner from "../../img/code/canoo/mobile-banner.png"

import "../style/DetailsPage.css"

const Canoo = () => {
    return (
        <div>
            <Navigation active="code"/>
            <MobileNavigation active="code"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Software Operations @ Canoo</h2>
                        <p>Jan 2021 - Apr 2021 | Frontend Engineering, UI Design | React, Tailwind CSS, and Figma | Work Experience</p>
                    </div>    

                    <div className="text-content-p">
                        <p>As an intern on the Software Operations team, I worked on a variety of internal tools including a company KPI dashboard, a vehicle software deployment tool, and a vehicle tracking tool.</p>
                        <p>Using primarily <span className="bold-body">React and Tailwind CSS</span>, I was able to not only build features on the frontend, but also got a chance to explore <span className="bold-body">UI design</span>. Here are some of the things that I worked on at Canoo.</p>
                        <div className="bullet-list">
                            <p>- Created an<span className="bold-body"> NPM graph wrapper package</span> around Victory charts to improve ease of development when creating data visualizations.</p>
                            <p>- Took charge of <span className="bold-body">redesigning</span> an internal vehicle software deployment tool to give it a brand new UI and improved user experience. </p>
                            <p>- Built and designed a <span className="bold-body">vehicle tracking tool</span> from scratch, fetching and parsing data from the backend, and designing and building the overall page layout. This application allows users to monitor information about Canoo's test vehicles.</p>
                            <p>- Implemented a <span className="bold-body">walkthrough tutorial component</span> to guide users through the user flow of various internal applications</p>
                        </div>
                        <ButtonLink text="View company website →" link="https://www.canoo.com/"/>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default Canoo
