import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/forfun/redfin-stay/banner.png"
import MobileBanner from "../../img/forfun/redfin-stay/mobile-banner.png"
import OwnerDash from "../../img/forfun/redfin-stay/owner-dash.png"
import HomePage from "../../img/forfun/redfin-stay/home-page.png"
import Landing from "../../img/forfun/redfin-stay/landing.png"
import Map from "../../img/forfun/redfin-stay/map.png"
import LDP from "../../img/forfun/redfin-stay/ldp.png"

import "../style/DetailsPage.css"

const RedfinStay = () => {
    return (
        <div>
            <Navigation active="for-fun"/>
            <MobileNavigation active="for-fun"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Short term rentals on Redfin (NOT REAL)</h2>
                        <p>July 2022 | Hackathon Project | Product Concept Design</p>
                    </div>    

                    <div className="text-content-p">
                        <p>As a part of the Redfin Summer Hackathon, I worked with a team of 4 other Redfinnians to design and build Redfin Stay. This feature allows users to book short-term rentals on Redfin.</p>
                        <p>This project was a part of a Hackathon meaning that it does not actually exist nor has plans to exist within Redfin’s product. Here was our design process.</p>                        
                        <ButtonLink text="View Figma file →" link="https://www.figma.com/file/29LUaaYkMIHyjx67ZWzHbB/Vicky-Li---Redfin-Stay?node-id=1%3A935"/>
                    </div>

                    <div>
                        <h3>Owner Dashboard </h3>
                        <p>The journey starts on the seller side with the owner dashboard page.</p>
                        <img src={OwnerDash} alt="screens" draggable={false} />
                        <p>In order to incentivize sellers into listing for short-term rentals, Redfin provides statistics to highlight the value that it could bring to the user. </p>
                        <p>If the seller has already listed their property on Redfin Stay, the dashboard will display fast facts about their listing and allow them to modify important details about their rentals like available dates, price, and more.</p>
                    </div>

                    <div>
                        <h3>Redfin Stay Home Page </h3>
                        <p>Next, we focused on the renter side by designing the landing page on redfin.com for Stay.</p>
                        <img src={HomePage} alt="screens" draggable={false} />
                        <p>We first redesigned the home cards to include details specific to short-term rentals like price per night, rating, and the amount of guests it can fit. </p>
                        <p>Next, we focused on revamping the search function to accommodate a short-term rental search. We modified it to include preferred rental dates and number of guests.</p>
                    </div>

                    <div>
                        <h3>redfin.com/stay </h3>
                        <p>We then worked on creating a modern and clean page that would communicate the value of Redfin Stay for both renters and sellers.</p>
                        <img src={Landing} alt="screens" draggable={false} />
                        <p>When designing our product, we also thought about how we could position it within the existing short-term rental market by taking advantage of it being on the Redfin platform.</p>
                        <p>One of the main advantages for sellers was that, even in a slow housing market, they can still easily list it for short-term rentals on Redfin until someone is ready to buy. This means that sellers can continue to make profit from their property at any time.</p>
                        <p>Furthermore, Redfin’s expansive network of home service providers can ensure a smoother process for getting the property ready to rent. </p>
                        <p>For renters, the main advantage is Redfin’s existing search features that can make finding a place a much easier and better experience.</p>
                    </div>

                    <div>
                        <h3>Map View </h3>
                        <p>Next, we worked on creating a new map view with short-term specific search functions and listing cards.</p>
                        <img src={Map} alt="screens" draggable={false} />
                    </div>

                    <div>
                        <h3>Listing Details Page </h3>
                        <p>Lastly, we created a new listing details page for properties that includes details relevant to short-term rentals such as seller details, pricing details, and nearby attractions.</p>
                        <img src={LDP} alt="screens" draggable={false} />
                    </div>

                </div>
                <Footer />
            </div>
        </div>
    )
}
export default RedfinStay
