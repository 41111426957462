import React from "react"
import { Router, Location } from "@reach/router"

import "./App.css"

import Landing from "./pages/Landing"
import Code from "./pages/Code"
import Design from "./pages/Design"
import ForFun from "./pages/ForFun"
import Redfin from "./pages/design/Redfin"
import Together from "./pages/design/Together"
import Huddle from "./pages/design/Huddle"
import WDB from "./pages/design/WDB"
import Circles from "./pages/design/Circles"

import Bill from "./pages/code/Bill"
import Canoo from "./pages/code/Canoo"
import HuddleCode from "./pages/code/HuddleCode"
import Decal from "./pages/code/Decal"
import CS70 from "./pages/code/CS70"
import Data8 from "./pages/code/Data8"

import HappyDrops from "./pages/forfun/HappyDrops"
import RedfinStay from "./pages/forfun/RedfinStay"

import OnRouteChangeWorker from "./components/OnRouteChangeWorker";


function App() {
    console.log('Your process.env.PUBLIC_URL', process.env.PUBLIC_URL)
    
    const OnRouteChange = ({ action }) => (
        <Location>
          {({ location }) => <OnRouteChangeWorker location={location} action={action} />}
        </Location>
    )

    return (
        <div className="App">
            <Router primary={false}>
                <Landing path="/" />
                <Code path="/code" />
                <Design path="/design" />
                <ForFun path="/for-fun" />
                <Redfin path="/design/redfin" />
                <Together path="/design/together" />
                <Huddle path="/design/huddle" />
                <WDB path="/design/wdb" />
                <Circles path="/design/circles" />
                <Bill path="/code/bill"/>
                <Canoo path="/code/canoo"/>
                <HuddleCode path="/code/huddle"/>
                <Decal path="/code/decal"/>
                <CS70 path="/code/cs70"/>
                <Data8 path="/code/data8"/>
                <HappyDrops path="/for-fun/happy-drops"/>
                <RedfinStay path="/for-fun/redfin-stay"/>
            </Router>
            <OnRouteChange
                action={() => {
                    window.scrollTo(0, 0);
                }}
            />
        </div>
    )
}

export default App
