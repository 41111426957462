import React from "react"
import { Link } from "@reach/router"

import Ham from "./img/ham.png"

import "./style/MobileNavigation.css"
const MobileNavigation = (props) => {
    function myFunction() {
        var x = document.getElementById("myLinks");
        if (x.style.display === "flex") {
          x.style.display = "none";
        } else {
          x.style.display = "flex";
        }
      }

    return (
        <div className="mobile-nav">
            <a href="javascript:void(0);" class="ham-icon" onClick={myFunction}><img src={Ham} alt="hamburger icon" className="ham" draggable={false} /></a>
            <div id="myLinks" style={{display: "none"}}>
            <Link to="/" className={(props.active === "about" ? "nav-elem-mobile active" : "nav-elem-mobile")}>
                About Me
            </Link>
            <Link to="/design" className={(props.active === "design" ? "nav-elem-mobile active" : "nav-elem-mobile")}>
                Design
            </Link>
            <Link to="/code" className={(props.active === "code" ? "nav-elem-mobile active" : "nav-elem-mobile")}>
                Code
            </Link>
            <Link to="/for-fun" className={(props.active === "for-fun" ? "nav-elem-mobile active" : "nav-elem-mobile")}>
                For Fun
            </Link>
            </div>
        </div>
    )
}
export default MobileNavigation
