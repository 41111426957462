import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/code/cs70/banner.png"
import MobileBanner from "../../img/code/cs70/mobile-banner.png"

import "../style/DetailsPage.css"

const CS70 = () => {
    return (
        <div>
            <Navigation active="code"/>
            <MobileNavigation active="code"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Discrete Math and Probability Theory</h2>
                        <p>August 2020 - December 2020 | Academic Intern | CS 70</p>
                    </div>    

                    <div className="text-content-p">
                        <p>UC Berkeley’s CS70 course is often the last course students take before they can declare the Computer Science major. Given the GPA cutoff needed to declare, this course is often incredibly stressful for students intending CS. </p>
                        <p>Having taken this class the summer before I helped teach it, I felt all the pains and pressures of the course myself. Being able to be an AI for this class was an extremely fulfilling experience. I got the chance to lead discussion rooms of up to 10 students, prompting questions, answering questions, and my favorite: getting to vent with them about their stresses and how daunting this course can be to calm their nerves.</p>                        
                    </div>

                    <div>
                        <p>Some of the concepts that I helped teach included <span className="bold-body">error correcting codes, computability, Markov chains, random variables, RSA, proofs</span>, and more.</p>
                    </div>

                    <ButtonLink text="View course website →" link="https://www.eecs70.org/"/>                    
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default CS70
