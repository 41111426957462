import React from "react"
import { Link } from "@reach/router"

import "./style/Card.css"
const Card = (props) => {
    return (
        <div className="card-container">
            <Link to={props.link} >
                <img className="card-image" src={props.image} alt="icon" />
            </Link>
            <p className="card-title">{props.title}</p>
            <p className="card-description">{props.description}</p>
        </div>
    )
}
export default Card
