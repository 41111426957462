import React from "react"

import "./style/Footer.css"

import FooterBack from "../img/footer.png"
import MobileBack from "../img/mobile-footer.png"

const Footer = () => {
    return (
        <div className="footer">
            <div className="hidden">
                <img
                    src={FooterBack}
                    className="footer-bg"
                    alt="footer"
                    draggable={false}
                />
                <img
                    src={MobileBack}
                    className="mobile-footer-bg"
                    alt="footer"
                    draggable={false}
                />
            </div>

            <div className="footer-text">
                <h4 style={{marginTop: 20 + "px"}}>Let's stay connected!</h4>
                <a href="https://linkedin.com/in/victoriayli" target="_blank" rel="noreferrer">linkedin.com/in/victoriayli</a>
                <p>© Victoria Li 2023</p>
            </div>
        </div>
    )
}
export default Footer
