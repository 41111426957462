import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/forfun/happy-drops/banner.png"
import MobileBanner from "../../img/forfun/happy-drops/mobile-banner.png"
import Ideas from "../../img/forfun/happy-drops/ideas.png"
import Final from "../../img/forfun/happy-drops/final.png"
import Gif1 from "../../img/forfun/happy-drops/gif1.gif"
import Gif2 from "../../img/forfun/happy-drops/gif2.gif"
import Gif3 from "../../img/forfun/happy-drops/gif3.gif"
import IG from "../../img/forfun/happy-drops/instagram.png"
import Illustrations from "../../img/forfun/happy-drops/illustrations.png"


import "../style/DetailsPage.css"

const HappyDrops = () => {
    return (
        <div>
            <Navigation active="for-fun"/>
            <MobileNavigation active="for-fun"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Creating cute things that make people smile</h2>
                        <p>March 2021 - August 2021 | Startup | Brand Design, Business Dev</p>
                    </div>    

                    <div className="text-content-p">
                        <p>After finishing up my first SWE internship, I was itching to work on something that was creative and fully my own. </p>
                        <p>I’ve always loved jewelry, and I’m a big collector myself. I decided to start my own jewelry business which later turned into selling stickers, illustrations, and more!</p>                        
                        <ButtonLink text="View Etsy page + reviews →" link="https://www.etsy.com/shop/HappyDropsDesigns/"/>
                    </div>

                    <div>
                        <h3>Brand Identity </h3>
                        <p>My number one goal was to create a brand that was approachable, fun, and personal. I wanted to design the brand identity around this using various colors, lighthearted symbols like the smiley face, and a “sketch-y” font.</p>
                        <p>Here are some of the final concepts I came up with!</p>
                        <img src={Ideas} alt="screens" draggable={false} />
                        <p>Below are some of the materials from my final design iteration</p>
                        <img src={Final} alt="screens" draggable={false} />
                    </div>

                    <div>
                        <h3>Getting Initial Traction </h3>
                        <p>As with any business, getting those first few sales or interactions is always the most difficult. Thus, I knew I had to get creative with my marketing.</p>
                        <div>
                            <h4>Storytelling as marketing</h4>
                            <p>Through my marketing, I emphasized my story more than anything. I wanted to be as transparent as possible with my audience in communicating my vision, my why, and the struggles and successes in running a business. I believe that the best way to gain trust and the attention of an audience is through capturing them in your story.</p>
                            <p>Using mostly TikTok, I was able to convey my story through video and capture an initial audience that brought in nearly <span className="bold-body">100 sales</span> in the first two weeks. </p>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <img src={Gif1} alt="screens" draggable={false} style={{width: 25 + '%', marginRight: 40 + "px"}}/>
                                <img src={Gif2} alt="screens" draggable={false} style={{width: 25 + '%', marginRight: 40 + "px"}}/>
                                <img src={Gif3} alt="screens" draggable={false} style={{width: 25 + '%'}}/>
                            </div>
                            <ButtonLink text="View TikTok →" link="https://www.tiktok.com/@happy.drops"/>
                        </div>
                    </div>

                    <div>
                        <h3>Leveraging Instagram </h3>
                        <p>Past TikTok, I also leveraged Instagram to market my products. Knowing that Instagram was pushing Reels at the time, I took advantage of using Instagram Reels as well as stories and posts.</p>
                        <p>Furthermore, I prioritized post quality over everything else, utilizing professional equipment to maintain proper lighting, setup, and photography. I also gave the posts personality by hand-writing product features and details.</p>
                        <img src={IG} alt="screens" draggable={false} />
                        <ButtonLink text="View Instagram →" link="https://www.instagram.com/happ.drops/"/>
                    </div>

                    <div>
                        <h3>Expanding the Product Line </h3>
                        <p>After working on jewelry for some time, I wanted to explore using Procreate to create digital illustration prints and stickers. Working with a new type of product required many different materials and sourcing from new suppliers. I had to evaluate what my best options were for printing and marketing these new products.</p>
                        <p>Below, I included a few of my favorite illustrations.</p>
                        <img src={Illustrations} alt="screens" draggable={false} />
                    </div>

                    <div>
                        <div>
                            <h3>Building Connections </h3>
                            <p>One of my greatest challenges was figuring out how to balance building trust and connection with the customer while managing many orders at a time. I wanted to ensure that all of my customers were satisfied with their orders. I found a couple methods that were highly effective in managing customer service:</p>
                        </div>
                        <div>
                            <h4>🎨 Personalizing orders</h4>
                            <p>I made every order unique by included hand-painted backing cards and hand-written packaging. This built trust and connection between me and the customer by showing the time and care put into their order.</p>
                        </div> 
                        <div>
                            <h4>☎️ Communicating timelines</h4>
                            <p>At first, I struggled with being upfront with customers in terms of timelines. I would stay up almost all night to finish packing orders to get them in on time during high traffic periods. I later discovered that customers tend to be forgiving with small businesses, and being honest is a better way to build trust than to compromise quality or your own sanity (‘:</p>
                        </div>   
                        <div>
                            <h4>⏰ Timeliness in response</h4>
                            <p>Whenever customers reached out, whether it be to thank me or to resolve a problem with their order, I always made sure to be timely in responding. Messaging a customer takes very little time but makes all the difference to them so they don’t feel left in the dark or unheard.</p>
                        </div>                    
                    </div>

                    <div>
                        <h3>Takeaways </h3>
                        <p>Happy Drops is probably one of the most rewarding projects I have been able to work on during college. Not only was it a great excercise in managing all aspects of my own business, but it also allowed me to meet new folks online and build a small community surrounding it. </p>
                    </div>

                </div>
                <Footer />
            </div>
        </div>
    )
}
export default HappyDrops
