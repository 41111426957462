import React from "react"

import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import MobileNavigation from "../../components/MobileNavigation"
import ButtonLink from "../../components/ButtonLink"

import Banner from "../../img/code/decal/banner.png"
import MobileBanner from "../../img/code/decal/mobile-banner.png"

import "../style/DetailsPage.css"

const Decal = () => {
    return (
        <div>
            <Navigation active="code"/>
            <MobileNavigation active="code"></MobileNavigation>
            <div className="content">
                <img src={Banner} className="banner"/>
                <img src={MobileBanner} className="mobile-banner"/>
                <div className="text-content">
                    <div className="text-content-heading">                    
                        <h2>Introduction to Full Stack Development</h2>
                        <p>August 2020 - December 2021 | Founder and Design/Frontend TA | CS 198-099</p>
                    </div>    

                    <div className="text-content-p">
                        <p>In my second semester at UC Berkeley, I noticed that there was a significant gap in education when it came to industry-applicable development skills and knowledge. After attending my first hackathon, I was more excited than ever to build. As I was looking for internships in the industry, I realized there were so many skills that I still hadn’t learned. </p>
                        <p>Thus, I set out to build <span className="bold-body">Web Development at Berkeley</span>: a place where all students, regardless of background, have a safe and exciting place to learn and apply their development skills.</p>
                        <p>With education at the <span className="bold-body">forefront</span> of our mission, we set out to start the first full stack development course at UC Berkeley that any student could enroll in. Our course, designed for beginners and taught by other students, seeks to provide students a <span className="bold-body">comprehensive full stack education</span> with hands-on assignments and projects along the way. In just 2 years, our club has been able to teach over <span className="bold-body">400</span> students about the joys of development. </p>
                        <p>I got the amazing chance to teach parts of the course, including concepts in <span className="bold-body">frontend development at UI/UX design</span>.</p>
                        
                    </div>

                    <ButtonLink text="View course website →" link="https://www.fullstackdecal.com/"/>
                    <div>
                        <p>Web Development at Berkeley is an ever-evolving and growing organization! Click here to learn more about what we do.</p>
                        <ButtonLink text="Learn more →" link="https://www.webatberkeley.org/"/>
                    </div>
                    
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default Decal
