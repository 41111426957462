import React from "react"
import { Link } from "@reach/router"

import "./style/ButtonLink.css"
const ButtonLink = (props) => {
    return (
        <div className="button-wrapper">
            <a href={props.link} target="_blank" rel="noreferrer">{props.text}</a>
        </div>
    )
}
export default ButtonLink
