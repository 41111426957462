import React from "react"

import Navigation from "../components/Navigation"
import MobileNavigation from "../components/MobileNavigation"
import Footer from "../components/Footer.js"
import Card from "../components/Card"

import Line from "../img/grad-line.png"

import HappyDrops from "../img/forfun/happy-drops/card.png"
import RedfinStay from "../img/forfun/redfin-stay/card.png"

import "./style/Code.css"

const ForFun = () => {
    return (
        <div>
             <Navigation className="nav" active="for-fun" />
             <MobileNavigation active="for-fun"></MobileNavigation>
             <div className="body-content">
                <div className="heading">   
                    <h1>For Fun</h1>
                    <div className="header-p">
                        <p>Some things I’ve worked on in my spare time that I enjoyed!</p>
                    </div>
                </div>
                <img src={Line} className="line"/>
                <div className="work-section" style={{marginTop: 60 + "px"}}>
                    <div className="some-work">
                        <Card link="/for-fun/happy-drops" image={HappyDrops} title="Creating cute things that make people smile" description="Brand Design, Business Dev | Startup"></Card>
                        <Card link="/for-fun/redfin-stay" image={RedfinStay} title="Redfin Short term rentals (NOT REAL)" description="Product Concept Design | Hackathon Project"></Card>
                    </div>
                </div>
             </div>
             <Footer/>
        </div>
    )
}
export default ForFun